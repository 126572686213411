import { decimalsObj } from '~/constants/general'

export default (unit: string) => {
  const decimals = unit === '' ? 1 : decimalsObj[unit.trim()]
  let totalDecimalsString = ''

  for (let index = 0; index < decimals; index++) {
    totalDecimalsString += '0'
  }
  if (totalDecimalsString.length) totalDecimalsString = '.' + totalDecimalsString

  return `#,##0${totalDecimalsString}`
}
