import { DateTime } from 'luxon'
import { DateFormats } from '~/constants/general'
import { reformat } from '~/utils/date'

export const getObjectToDateXLSX = (value: string) => {
  const format = DateFormats.dd_MM_yyyy
  const dateOneFormat = reformat(value, format)
  const date = DateTime.fromFormat(dateOneFormat, format)
  return {
    f: `DATE(${date.year}, ${date.month}, ${date.day})`,
    z: format,
  }
}

export const getObjectToNumberXLSX = (value: any, format: string) => {
  return {
    t: 'n',
    v: value,
    z: format,
  }
}
