export enum DataSourceRange {
  START_YEAR = 2015,
  END_YEAR = 2040,
}

export const START_DATE_MONTHLY = 2021
export const END_DATE_MONTHLY = new Date().getFullYear()

export const LITHIUM_CARBONATE_LABEL = 'Lithium Carbonate'
export const LITHIUM_HYDROXIDE_LABEL = 'Lithium Hydroxide'

export enum ChartRangeFilterMode {
  ALL_TIME = 'all-time',
  CUSTOM = 'custom',
}
